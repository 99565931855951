import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { AngularMaterialModule } from '../../../../../../../src/app/angular-material.module';
import { PipesModule } from '../../../../../../../src/app/pipes/pipes.module';
import { SnackbarService } from '../../../../../../../src/app/services/snackbar.service';
import { SpinnerService } from '../../../../../../../src/app/services/spinner.service';
import { ToolbarComponent } from '../../../../../../../src/app/views/general/toolbar/toolbar.component';
import { fieldUserImage } from '../../../enums/fields';
import { UserData } from '../../../models/user';
import { ImageService } from '../../../services/image.service';
import { ConfirmHandlerService } from '../../../../../../../src/app/services/confirm-handler.service';
import { FormHelperService } from '../../../../../../../src/app/utils/form-helper.service';
import { LoginStateFieldComponent } from "../../shared/login-state-field/login-state-field.component";
import { LanguageButtonComponent } from "../../../../../../../src/app/views/general/language-button/language-button.component";
import { FooterComponent } from "../../shared/footer/footer.component";
import { HeaderComponent } from "../../shared/header/header.component";
import { Globals } from '../../../global';
import { UserDataViewModel } from '../../../viewmodels/userdata-viewmodel.service';
import { Subscription } from 'rxjs';
import { AccountManager } from '../../../services/account-manager.service';
import { AuthService } from '../../../services/auth.service';
import { TranslateService } from '../../../localization/translate.service';
import { BoardData } from '../../../models/board';
import { BoarddataService } from '../../../services/boarddata.service';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';

@Component({
  selector: 'public-app-user-data',
  standalone: true,
  templateUrl: './public-user-data.component.html',
  styleUrl: './public-user-data.component.scss',
  imports: [
    AngularMaterialModule,
    CommonModule,
    ToolbarComponent,
    PipesModule,
    RouterModule,
    LoginStateFieldComponent,
    LanguageButtonComponent,
    FooterComponent,
    HeaderComponent,
    FormsModule
  ]
})
export class PublicUserDataComponent implements OnInit, OnDestroy {

  public imageUrl: any;

  static boardKey: string | null = null
  board: BoardData | null = null

  public enableAccountDelete = false

  private subscription: Subscription | null = null;

  constructor(
    public snackbarService: SnackbarService,
    private spinnerService: SpinnerService,
    private route: ActivatedRoute,
    private boardService: BoarddataService,
    public analytics: AngularFireAnalytics
  ) {
    analytics.setCurrentScreen('publicUserData')
    analytics.logEvent("Open-publicUserData")

    this.route.queryParams.subscribe(params => {
      PublicUserDataComponent.boardKey = params['bkey'];
    })
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  async ngOnInit() {

    if (!PublicUserDataComponent.boardKey) {
      console.log("No key")
      this.snackbarService.openDefaultErrorSnackBar()
      return
    }

    this.spinnerService.show()

    this.board = await this.boardService.getBoard(PublicUserDataComponent.boardKey)

    this.spinnerService.hide()
  }
}