import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AngularMaterialModule } from '../../../../../../../src/app/angular-material.module';
import { PipesModule } from "../../../../../../../src/app/pipes/pipes.module";
import { LocationService } from '../../../services/location.service';
import { LocationData } from '../../../models/location';
import { Globals } from '../../../global';

@Component({
  selector: 'app-location-overview',
  standalone: true,
  templateUrl: './location-overview.component.html',
  styleUrl: './location-overview.component.scss',
  imports: [
    CommonModule,
    AngularMaterialModule,
    PipesModule,
    FormsModule,
  ]
})
export class LocationOverviewComponent implements AfterViewInit {

  defaultLocations = [
    { 'display_name': 'Sagres, Vila do Bispo, Portugal', 'lon': -8.941924, 'lat': 37.0078288, 'geohash': 'ey9ds90dnp' },
    { 'display_name': 'El Palmar, Vejer de la Frontera, Spain', 'lon': -6.0626507, 'lat': 36.2383198, 'geohash': 'ey7wnhcr9b' },
    { 'display_name': 'Ericeira, Portugal', 'lon': -9.4170802, 'lat': 38.9632411, 'geohash': 'eycm9tn9q4' },
    { 'display_name': 'Peniche, Portugal', 'lon': -9.3786838, 'lat': 39.3568749, 'geohash': 'eycrfsdwkv' },
    { 'display_name': 'Lacanau, Nouvelle - Aquitaine, France', 'lon': -1.0784851, 'lat': 44.9776088, 'geohash': 'ezzpz7ytt3' },
    { 'display_name': 'Capbreton, Nouvelle-Aquitaine, France', 'lon': -1.4315213, 'lat': 43.6403659, 'geohash': 'ezybr2mxe1' },
    { 'display_name': 'Biarritz, Nouvelle-Aquitaine, France', 'lon': -1.5592776, 'lat': 43.4832523, 'geohash': 'ezwzkebs61' },
    { 'display_name': 'Santander, Cantabria, Spain', 'lon': -3.8100255, 'lat': 43.4618932, 'geohash': 'eztr1rcxgu' },
  ]

  constructor(
    public locationService: LocationService,
    public globals: Globals
  ) {
    this.resultList = this.defaultLocations.map((entry: any) => new LocationData(entry['display_name'], entry['lon'], entry['lat']));
  }

  @Output() locationPanel = new EventEmitter<void>();
  @Input() onSearch: ((result: LocationData) => void) | null = null;

  searchInProgress = false
  resultList: LocationData[] = []
  commitedQuery = ""

  @ViewChild('query', { static: true }) queryInput!: ElementRef;

  ngAfterViewInit() {
    setTimeout(() => {
      this.queryInput.nativeElement.focus();
    }, 300);
  }

  async onNewSearchQuery(searchQuery: string) {
    if (searchQuery.length > 3 && this.searchInProgress == false) {
      this.searchInProgress = true
      this.commitedQuery = searchQuery
      let result = await this.locationService.searchLocation(searchQuery)
      this.resultList = result.filter((entry: any) => entry['addresstype'] != 'state' && entry['addresstype'] != 'country').map((entry: any) => new LocationData(entry['display_name'], entry['lon'], entry['lat']));
      this.searchInProgress = false
    }
  }

  selectResult(result: LocationData) {
    if (!this.onSearch) {
      return
    }

    this.onSearch(result)
  }

  close() {
    this.locationPanel.emit();
  }
}
