<div class="vstack">
    <app-header [showBack]=false></app-header>

    <div class="content scroll-container padval container-height" style="--padding: 8px 16px 0 16px;--minus: 66px;">

        <div class="vstack">

            <!-- #1 Login buttons -->
            <div [hidden]="loginOrRegister === loginKey || emailSent === true || loginOrRegister === loginKeyEmail">

                <div class="spacer-xxl"></div>
                <h3 class="bold">{{'pwauth-headline' | translate}}</h3>
                <div class="spacer-xxl"></div>
            </div>

            <!-- #2 Login chosen -->
            <div
                [hidden]="loginOrRegister === null || emailSent === true || (loginOrRegister !== loginKey && loginOrRegister !== loginKeyEmail)">
                <div class="vstack">
                    <div class="spacer-l"></div>
                    <div class="hstack">
                        <p class="nav-icon material-symbols-outlined" (click)="resetLoginRegister()">
                            navigate_before
                        </p>
                        <div>
                            <div class="spacer-s"></div>
                            <h5>{{'back' | translate}}</h5>
                        </div>
                    </div>
                    <div [hidden]="loginOrRegister !== loginKeyEmail">

                        <div class="spacer-xxl"></div>
                        <h4>{{'pwauth-login-email' | translate}}</h4>
                        <div class="spacer-xl"></div>

                        <mat-form-field appearance="fill" color="primary" class="dimval" style="--w: 100%">
                            <input matInput [type]="'email'" placeholder="{{ 'email' | translate }}"
                                [(ngModel)]='email'>
                        </mat-form-field>

                        <div class="hstack">
                            <div class="spacer"></div>
                            <button [disabled]="!this.emailValid()" mat-flat-button color="primary"
                                (click)="sendLoginEmail()">
                                <span>{{'send' | translate}}</span>
                            </button>
                        </div>

                        <div class="spacer-xxl"></div>
                        <div class="hstack">
                            <div class="divider"></div>
                            <div class="spacer-l"></div>
                            <h3>{{'or' | translate}}</h3>
                            <div class="spacer-l"></div>
                            <div class="divider"></div>
                        </div>


                        <div class="spacer-xxl"></div>

                        <h4>{{'pwauth-login-pw' | translate}}</h4>
                        <div class="spacer-xl"></div>
                        <mat-form-field appearance="fill" color="primary" class="dimval" style="--w: 100%">
                            <input matInput [type]="'email'" placeholder="{{ 'email' | translate }}"
                                [(ngModel)]='email'>
                        </mat-form-field>

                        <mat-form-field appearance="fill" color="primary" class="dimval" style="--w: 100%">
                            <mat-label>{{'password' | translate}}</mat-label>
                            <input matInput [type]="hidePassword ? 'password' : 'text'" [(ngModel)]="password"
                                maxlength="20" name="password">
                            <button mat-icon-button matSuffix (click)="hidePassword = !hidePassword"
                                [attr.aria-label]="'Toggle password visibility'" [attr.aria-pressed]="!hidePassword">
                                <mat-icon *ngIf="!hidePassword"><span class="material-symbols-outlined">
                                        visibility
                                    </span></mat-icon>
                                <mat-icon *ngIf="hidePassword"><span class="material-symbols-outlined">
                                        visibility_off
                                    </span></mat-icon>
                            </button>
                        </mat-form-field>

                        <div class="hstack">
                            <div class="spacer"></div>
                            <button [disabled]="!this.emailValid() || !this.pwValid()" mat-flat-button color="primary"
                                (click)="loginWithPassword()">
                                <span>{{'pwauth-login' | translate}}</span>
                            </button>
                        </div>

                    </div>

                    <div [hidden]="loginOrRegister === loginKeyEmail">
                        <div class="spacer-xxl"></div>
                        <div class="spacer-xxl"></div>
                        <div class="hstack">
                            <div class="spacer"></div>
                            <div class="login-button" (click)="chooseLoginWithMail()">
                                <img src="../../../../assets/email.png" />
                                <span>Login with Email</span>
                            </div>
                            <div class="spacer"></div>
                        </div>
                        <div class="spacer-l"></div>
                        <div class="hstack">
                            <div class="spacer"></div>
                            <div class="login-button" (click)="loginWithApple()">
                                <img src="../../../../assets/apple.png" />
                                <span>Login with Apple</span>
                            </div>
                            <div class="spacer"></div>
                        </div>
                        <div class="spacer-l"></div>
                        <div class="hstack">
                            <div class="spacer"></div>
                            <div class="login-button" (click)="loginWithGoogle()">
                                <img class="spacer-xl" src="../../../../assets/google.png" />
                                <span>Login with Google</span>
                            </div>
                            <div class="spacer"></div>
                        </div>
                    </div>
                    <div [hidden]="loginOrRegister === loginKeyEmail">
                        <div class="spacer-xxl"></div>
                        <div class="spacer-xxl"></div>
                        <div class="hstack">
                            <div class="spacer-m"></div>
                            <div class="vstack">
                                <div class="hstack">
                                    {{ 'pwauth-request-order-accept-sign-in' | translate }}
                                </div>

                                <div class="spacer-m"></div>
                                <h6 class="">
                                    <a target="_blank" [routerLink]="['/terms']">{{ 'terms' | translate }}</a> - <a
                                        target="_blank" [routerLink]="['/privacy']">{{ 'data-protection' | translate
                                        }}</a>
                                </h6>
                            </div>
                        </div>
                    </div>
                </div>

            </div>


            <!-- #2 Register chosen -->
            <div
                [hidden]="loginOrRegister === null || emailSent === true || loginOrRegister === loginKey || loginOrRegister === loginKeyEmail">

                <div class="vstack">
                    <div class="spacer-l"></div>
                    <p (click)="chooseLogin()">
                        {{'pwauth-login-title' | translate}}
                        <span class="accent-1 superbold">{{'pwauth-login' | translate}}</span>
                    <p>
                    <div class="spacer-xxl"></div>

                    <div [hidden]="loginOrRegister !== registerKeyEmail">
                        <div class="hstack">
                            <p class="nav-icon material-symbols-outlined" (click)="resetLoginRegister()">
                                navigate_before
                            </p>
                            <div>
                                <div class="spacer-s"></div>
                                <h5>{{'pwauth-register' | translate}}</h5>
                            </div>
                        </div>

                        <div class="spacer-l"></div>
                        <mat-form-field appearance="fill" color="primary" class="dimval" style="--w: 100%">
                            <input type="email" matInput placeholder="{{ 'email' | translate }}" [(ngModel)]='email'>
                        </mat-form-field>
                        <div class="hstack">
                            <div class="spacer"></div>
                            <button [disabled]="!this.emailValid()" mat-flat-button color="primary"
                                (click)="register()">
                                <span>{{'send' | translate}}</span>
                            </button>
                        </div>
                        <div class="spacer-l"></div>
                        <div class="spacer-xs"></div>
                    </div>

                    <div [hidden]="loginOrRegister === registerKeyEmail">
                        <div class="spacer-l"></div>
                        <div class="hstack">
                            <div class="spacer"></div>
                            <div class="login-button" (click)="chooseRegisterWithMail()">
                                <img src="../../../../assets/email.png" />
                                <span>Sign up with Email</span>
                            </div>
                            <div class="spacer"></div>
                        </div>
                        <div class="spacer-l"></div>
                        <div class="hstack">
                            <div class="spacer"></div>
                            <div class="login-button" (click)="loginWithApple()">
                                <img src="../../../../assets/apple.png" />
                                <span>Sign up with Apple</span>
                            </div>
                            <div class="spacer"></div>
                        </div>
                        <div class="spacer-l"></div>
                        <div class="hstack">
                            <div class="spacer"></div>
                            <div class="login-button" (click)="loginWithGoogle()">
                                <img class="spacer-xl" src="../../../../assets/google.png" />
                                <span>Sign up with Google</span>
                            </div>
                            <div class="spacer"></div>
                        </div>
                    </div>
                    <div class="spacer-xxl"></div>
                    <div class="spacer-xxl"></div>
                    <div class="hstack">
                        <div class="spacer-m"></div>
                        <div class="vstack">
                            <div class="hstack">
                                {{ 'pwauth-request-order-accept-sign-up' | translate }}
                            </div>

                            <div class="spacer-m"></div>
                            <h6 class="">
                                <a target="_blank" [routerLink]="['/terms']">{{ 'terms' | translate }}</a> - <a
                                    target="_blank" [routerLink]="['/privacy']">{{ 'data-protection' | translate }}</a>
                            </h6>
                        </div>
                    </div>

                </div>
            </div>

            <!-- #4 EMAIL sent -->
            <div [hidden]="emailSent === false">
                <div class="spacer-xxl"></div>
                <h4>{{ 'pwauth-email-sent' | translate }}</h4>
                <div class="spacer-xxl"></div>
                <div class="right-text">
                    {{email}}
                </div>
                <div class="spacer-l"></div>
                <div class="hstack">
                    <div class="spacer"></div>
                    <button mat-flat-button color="primary" (click)="correctMail()">
                        <span>{{'pwauth-email-correct' | translate}}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>