<div class="vstack">
    <app-header></app-header>
    <div class="content scroll-container padval container-height" style="--padding: 8px 16px 0 16px;--minus: 66px;">
        <div class="vstack">
            <div class="spacer-s"></div>
            <h5 class="superbold">{{ 'ab-headline-1' | translate }}</h5>
            <div class="spacer-l"></div>
            <!--Gallery-->
            <div class="vstack accent-3-verylight maval" style=" --margin: 0 0 32px 0;">
                <div class="hstack">
                    <div class="dimval" [ngStyle]="{'--w': globals.framewidth/2 - 20 + 'px', '--h': '300px'}">
                        <div class="zstack">
                            <div class="vstack">
                                <div class="spacer-xxxxl"></div>
                                <div class="spacer-xxxxl"></div>
                                <h4 class="alert-red center-text" *ngIf="this.url1 == null">{{ 'ab-publish-title'
                                    |
                                    translate }}</h4>
                            </div>

                            <div class="imgval radiusval" style="--h: 300px;--w: 100%; --radius: 8px 0 0 0;"
                                onclick="document.getElementById('file1').click()">
                                <img src='{{url1}}' alt="">
                            </div>
                            <label for="file1">
                                <span class="upload-icon material-symbols-outlined">
                                    add_photo_alternate
                                </span>
                                <input class="file-upload" accept="image/*" type="file" name="file1" id="file1"
                                    (change)="selectFile($event, 1)">
                            </label>
                        </div>
                    </div>
                    <div class="vstack">
                        <div class="zstack">
                            <div class="imgval radiusval" style="--radius: 0 8px 0 0;"
                                [ngStyle]="{'--w': globals.framewidth/2 - 20 + 'px', '--h': '146px'}"
                                onclick="document.getElementById('file2').click()">
                                <img src='{{url2}}' alt="">
                            </div>
                            <label for="file2">
                                <span class="upload-icon material-symbols-outlined">
                                    add_photo_alternate
                                </span>
                                <input class="file-upload" accept="image/*" type="file" name="file2" id="file2"
                                    (change)="selectFile($event, 2)">
                            </label>
                        </div>

                        <p class="spacer-s"></p>
                        <div class="zstack">
                            <div class="imgval radiusval" style="--radius: 0 0 0 0;"
                                [ngStyle]="{'--w': globals.framewidth/2 - 20 + 'px', '--h': '146px'}"
                                onclick="document.getElementById('file3').click()">
                                <img src='{{url3}}' alt="">
                            </div>
                            <label for="file3">
                                <span class="upload-icon material-symbols-outlined">
                                    add_photo_alternate
                                </span>
                                <input class="file-upload" accept="image/*" type="file" name="file3" id="file3"
                                    (change)="selectFile($event, 3)">
                            </label>
                        </div>
                    </div>
                </div>
                <p class="spacer-s"></p>
                <div class="hstack">
                    <div class="zstack">
                        <div class="imgval radiusval" style="--radius: 0 0 0 8px;"
                            [ngStyle]="{'--w': globals.framewidth/2 - 20 + 'px', '--h': '150px'}"
                            onclick="document.getElementById('file4').click()">
                            <img src='{{url4}}' alt="">
                        </div>
                        <label for="file4">
                            <span class="upload-icon material-symbols-outlined">
                                add_photo_alternate
                            </span>
                            <input class="file-upload" accept="image/*" type="file" name="file4" id="file4"
                                (change)="selectFile($event, 4)">
                        </label>
                    </div>
                    <div class="zstack">
                        <div class="imgval radiusval" style="--radius: 0 0 8px 0;"
                            [ngStyle]="{'--w': globals.framewidth/2 - 20 + 'px', '--h': '150px'}"
                            onclick="document.getElementById('file5').click()">
                            <img src='{{url5}}' alt="">
                        </div>
                        <label for="file5">
                            <span class="upload-icon material-symbols-outlined">
                                add_photo_alternate
                            </span>
                            <input class="file-upload" accept="image/*" type="file" name="file5" id="file5"
                                (change)="selectFile($event, 5)">
                        </label>
                    </div>
                </div>
            </div>
            <!--values-->
            <h5 class="superbold">{{ 'ab-headline-2' | translate }}</h5>
            <div class="spacer-l"></div>
            <div *ngIf="registerForm != undefined && boardData != null" class="vstack">

                <form [formGroup]="registerForm" novalidate>

                    <mat-form-field appearance="fill" color="primary" class="dimval" style="--w: 100%">
                        <mat-label>{{ 'ab-title' | translate }}</mat-label>
                        <input maxlength="50" matInput formControlName="title" required>
                    </mat-form-field>
                    <mat-form-field appearance="fill" color="primary" class="dimval" style="--w: 100%">
                        <mat-label>{{ 'ab-subtitle' | translate }}</mat-label>
                        <input maxlength="50" matInput formControlName="subtitle" required>
                    </mat-form-field>

                    <mat-form-field appearance="fill" color="primary" class="dimval" style="--w: 100%">
                        <mat-label>{{ 'ab-description' | translate }}</mat-label>
                        <textarea matInput maxlength="500" rows="4" formControlName="aboutOffer" required></textarea>
                    </mat-form-field>

                </form>

                <div class="hstack">

                    <mat-form-field color="primary" class="dimval" style="--w: 50%; --h: 100px;">
                        <mat-label>{{ 'ab-length' | translate }}</mat-label>
                        <mat-select [(ngModel)]="boardData.length" required>
                            <div *ngFor="let i of [].constructor(133); let index = index">
                                <mat-option [value]="(index * 2.54 + 84)">{{ (index* 2.54 + 84) | cmToFeetInch }}
                                    ({{
                                    (index* 2.54 + 84)
                                    |
                                    roundCm }}
                                    cm)</mat-option>
                            </div>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field color="primary" class="dimval" style="--w: 50%; --h: 100px;">
                        <mat-label>{{ 'ab-volume' | translate }}</mat-label>
                        <mat-select [(ngModel)]="boardData.volume">
                            <div *ngFor="let i of [].constructor(400); let index = index">
                                <mat-option [value]="(index + 5)">{{ (index + 5) }} Liters</mat-option>
                            </div>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="hstack">
                    <mat-form-field color="primary" class="dimval" style="--w: 50%; --h: 100px;">
                        <mat-label>{{ 'ab-width' | translate }}</mat-label>
                        <mat-select [(ngModel)]="boardData.width">
                            <div *ngFor="let i of [].constructor(36 * 4); let index = index">
                                <mat-option [value]="(index * 0.3175 + 38)">{{ (index* 0.3175 + 38) | cmToInch }}
                                    ({{
                                    (index * 0.3175 + 38)
                                    |
                                    roundCm2 }}
                                    cm)</mat-option>
                            </div>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field color="primary" class="dimval" style="--w: 50%; --h: 100px;">
                        <mat-label>{{ 'ab-thickness' | translate }}</mat-label>
                        <mat-select [(ngModel)]="boardData.thick">
                            <div *ngFor="let i of [].constructor(10 * 4); let index = index">
                                <mat-option [value]="(index * 0.3175 + 3)">{{ (index* 0.3175 + 3) | cmToInch }}
                                    ({{
                                    (index* 0.3175 + 3)
                                    |
                                    roundCm2 }}
                                    cm)</mat-option>
                            </div>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="hstack">
                    <mat-form-field color="primary" class="dimval" style="--w: 50%; --h: 100px;">
                        <mat-label>{{ 'ab-condition' | translate }}</mat-label>
                        <mat-select [(ngModel)]="boardData.isNew">
                            <mat-option [value]="true">{{ 'ab-condition-new' | translate }}</mat-option>
                            <mat-option [value]="false">{{ 'ab-condition-used' | translate }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="spacer-xl"></div>
                <h4 class="superbold">{{ 'ab-sell-title' | translate }}</h4>
                <div class="spacer-l"></div>

                <mat-form-field appearance="fill" color="primary" class="dimval" style="--w: 100%">
                    <mat-label>{{ 'ab-price' | translate }}</mat-label>
                    <mat-select required [(ngModel)]="boardData.price">
                        <div *ngFor="let i of [].constructor(600); let index = index">
                            <mat-option [value]="(index + 1) * 5">{{ (index + 1) * 5 }} €</mat-option>
                        </div>
                    </mat-select>
                </mat-form-field>

                <div>

                    <mat-form-field appearance="fill" color="primary" class="dimval" style="--w: 100%">
                        <mat-label>{{ 'ab-location-title' | translate }}</mat-label>
                        <input (click)="openLocationOverlay()" matInput [(ngModel)]="boardData.location" required>
                    </mat-form-field>
                </div>

                <mat-form-field appearance="fill" color="primary" class="dimval" style="--w: 100%">
                    <mat-label>{{ 'ab-availability' | translate }}</mat-label>
                    <mat-select [(ngModel)]="boardData.availability" required>
                        <div *ngFor="let i of [Availability.available, 
                            Availability.availableFrom, 
                            Availability.reserved, 
                            Availability.sold];">
                            <mat-option [value]="i">{{ i | available }}</mat-option>
                        </div>
                    </mat-select>
                </mat-form-field>

                <div *ngIf="boardData.availability == Availability.availableFrom">
                    <app-calendar [board]="boardData" [manageable]="true"></app-calendar>
                </div>

                <div class="spacer-xxl"></div>
                <div class="spacer-xxl"></div>

                <div class="hstack">
                    <div class="vstack">
                        <button [disabled]="boardData.key == null" mat-flat-button color="warn" (click)="deleteBoard()">
                            <span>{{ 'delete' | translate }}</span>
                        </button>
                        <div class="spacer-xl"></div>
                        <div *ngIf="boardData.publish == false">
                            <div class="spacer-xxxl"></div>
                        </div>
                    </div>
                    <div class="spacer"></div>
                    <div class="vstack">
                        <button [disabled]="!canPublish() || !dataHasChanged(true)" mat-flat-button color="primary"
                            (click)="saveAndPublishBoard()">
                            <span *ngIf="boardData.publish == false">{{ 'ab-publish' | translate }}</span>
                            <span *ngIf="boardData.publish == true">{{ 'save' | translate }}</span>
                        </button>
                        <div class="spacer-xl"></div>
                        <div *ngIf="boardData.publish == false" class="right-text">
                            <h6 *ngIf="canSave() && dataHasChanged(false)" class="accent-1 isButton"
                                (click)="onSaveClicked()">
                                {{ 'ab-just-save-1' | translate }}</h6>
                            <h6 *ngIf="canSave() && dataHasChanged(false)" class="accent-1 isButton"
                                (click)="onSaveClicked()">
                                {{ 'ab-just-save-2' | translate }}</h6>
                            <h6 *ngIf="!canSave() || !dataHasChanged(false)" class="sec-color-light">{{ 'ab-just-save-1'
                                | translate }}</h6>
                            <h6 *ngIf="!canSave()|| !dataHasChanged(false)" class="sec-color-light">{{ 'ab-just-save-2'
                                | translate }}</h6>
                        </div>
                    </div>
                </div>
            </div>
            <div class="spacer-xxl"></div>
            <div class="spacer-xxl"></div>
            <div class="spacer-xxl"></div>
        </div>
    </div>
</div>