import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { environment } from "../environments/environment.prod";
import { SpinnerComponent } from '../../../../src/app/views/general/spinner/spinner.component';
import { SpinnerService } from '../../../../src/app/services/spinner.service';
import { ConsentService } from './services/content.service';
import { AngularMaterialModule } from '../../../../src/app/angular-material.module';
import { PipesModule } from "../../../../src/app/pipes/pipes.module";
import { take } from 'rxjs';
import { AccountManager } from './services/account-manager.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet, SpinnerComponent, AngularMaterialModule, PipesModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {

  constructor(
    public spinnerService: SpinnerService,
    public consentService: ConsentService,
    private accountService: AccountManager
  ) {

  }

  showBanner = false

  ngOnInit() {
    console.log("Start in production: " + environment.production);

    const sub = this.accountService.account.pipe(take(1)).subscribe(() => {
      this.showBanner = this.consentService.askForConsent()
    });
  }

  onUserConsentGiven() {
    this.showBanner = false
    this.consentService.grantConsent();
  }

  onUserConsentDenied() {
    this.showBanner = false
    this.consentService.denyConsent();
  }
}