import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContactDetailComponent } from './views/contact/contact-detail/contact-detail.component';
import { BoardListComponent } from './views/board/board-list/board-list.component';
import { BoardDetailComponent } from './views/board/board-detail/board-detail.component';
import { AddBoardComponent } from './views/board/add-board/add-board.component';
import { UserDataComponent } from './views/user/user-data/user-data.component';
import { UserCheckGuard } from './guards/usercheck.guard';
import { AuthGuard } from './guards/auth.guard';
import { PasswordlessAuthComponent } from './views/user/passwordless-auth/passwordless-auth.component';
import { ContactListComponent } from './views/contact/contact-list/contact-list.component';
import { WelcomeComponent } from './views/welcome/welcome.component';
import { ConditionsComponent } from './views/shared/privacy/conditions/conditions.component';
import { DataProtectionComponent } from './views/shared/privacy/data-protection/data-protection.component';
import { AddBoardInfoComponent } from './views/board/add-board-info/add-board-info.component';
import { PublicUserDataComponent } from './views/user/public-user-data/public-user-data.component';
import { BoardListerListComponent } from './views/board/board-lister-list/board-lister-list.component';
import { SupporterdetailComponent } from './views/supporter/supporterdetail/supporterdetail.component';
import { FinishRegistrationComponent } from './views/user/finish-registration/finish-registration.component';

const routes: Routes = [
    { path: '', component: WelcomeComponent, canActivate: [UserCheckGuard] },
    { path: 'search', component: BoardListComponent, canActivate: [UserCheckGuard] },
    { path: 'add', component: AddBoardComponent, canActivate: [AuthGuard] },
    { path: 'addinfo', component: AddBoardInfoComponent, canActivate: [UserCheckGuard] },
    { path: 'board', component: BoardDetailComponent, canActivate: [UserCheckGuard] },
    { path: 'contact', component: ContactDetailComponent, canActivate: [AuthGuard] },
    { path: 'contacts', component: ContactListComponent, canActivate: [AuthGuard] },
    { path: 'login', component: PasswordlessAuthComponent, canActivate: [UserCheckGuard] },
    { path: 'user', component: UserDataComponent, canActivate: [AuthGuard] },
    { path: 'myboards', component: BoardListComponent, canActivate: [AuthGuard] },
    { path: 'edit', component: AddBoardComponent, canActivate: [AuthGuard] },
    { path: 'terms', component: ConditionsComponent },
    { path: 'privacy', component: DataProtectionComponent },
    { path: 'legal', component: ConditionsComponent },
    { path: 'more', component: AddBoardInfoComponent, canActivate: [UserCheckGuard] },
    { path: 'lister', component: PublicUserDataComponent, canActivate: [UserCheckGuard] },
    { path: 'lboards', component: BoardListerListComponent, canActivate: [UserCheckGuard] },
    { path: 'supporter', component: SupporterdetailComponent, canActivate: [UserCheckGuard] },
    { path: 'finish', component: FinishRegistrationComponent, canActivate: [AuthGuard] },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        scrollPositionRestoration: 'disabled',
        anchorScrolling: 'disabled',
    }),
    ],
    exports: [RouterModule]
})

export class AppRoutingModule { }
export { routes };
