import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AccountManager } from '../services/account-manager.service';
import { SnackbarService } from '../../../../../src/app/services/snackbar.service';
import { Globals } from '../global';
import { LoginState } from '../enums/login-state';


@Injectable({
  providedIn: 'root',
})
export class AuthGuard {

  constructor(
    private router: Router,
    private accountManager: AccountManager,
    public snackbarService: SnackbarService,
    private globals: Globals
  ) {

  }

  private _unsubscribe: Subject<boolean> = new Subject<boolean>();

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {

    return new Promise((result) => {
      this.accountManager.loggedIn.pipe(
        takeUntil(this._unsubscribe)
      ).subscribe(loginState => {

        console.log('auth guard loginState: ' + loginState);

        if (loginState !== LoginState.pending) {

          this._unsubscribe.next(true);

          if (loginState === LoginState.loggedInNoUser) {
            if (state.url.includes('finish')) {
              result(true);
              return
            }
            this.router.navigate(['/finish']);
            result(false);
          } else if (loginState === LoginState.loggedOut) {
            // TODO window.location.origin
            this.router.navigate(['/login'], { queryParams: { 'continueUrl': this.globals.baseUrl() + state.url } });

            result(false);
          } else
            result(true);
        } else {
          this.router.navigate(['/login'], { queryParams: { 'continueUrl': this.globals.baseUrl() + state.url } });

          result(false);
        }
      });
    });
  }
}
