<div class="vstack chat">
    <app-header (buttonClick)="onActionButton()"></app-header>

    <div *ngIf="board" class="dimval vstack padval accent-3-verylight"
        style="--h: 76px; --w: 100%; --padding: 4px 16px;">

        <div class="hstack">
            <h3 class="hand">
                <span class="">{{board.length | cmToFeetInch}}</span>
                <span class=""> | {{board.volume}} Liter</span>
            </h3>

            <p class="spacer"></p>
            <h5 class="">{{board.price}} €</h5>
        </div>
        <div class="spacer-s"></div>
        <div class="hstack">
            <h6 *ngIf="(board.location?.length ?? 0) > 20">{{ board.location|
                slice:0:20}}..</h6>
            <h6 *ngIf="(board.location?.length ?? 0) <= 20">{{ board.location
                }}</h6>
            <p class="spacer"></p>
            <a [routerLink]="['/board']" [queryParams]="{ bkey: board.key }">
                <h6 class="accent-1 bold">{{ board.title }}</h6>
            </a>
        </div>

        <div class="spacer-l"></div>
    </div>

    <div id="messagefield" #messagefield class="content scroll-container padval container-height"
        style="--padding: 8px 16px;--minus: 244px;">
        <div class="vstack">
            <span *ngFor="let message of messages | async">
                <div
                    [ngClass]="{'message-block-left': message.senderKey != globals.user?.key, 'message-block-right': message.senderKey == globals.user?.key}">
                    <div
                        [ngClass]="{'message-left': message.senderKey != globals.user?.key, 'message-right': message.senderKey == globals.user?.key}">

                        <p class="mt-1 line-breaks">{{message.message}}</p>
                        <p class="spacer-m"></p>
                        <div class="hstack">
                            <h6 class="">{{message.senderName}}</h6>
                            <h6 class="spacer"></h6>
                            <h6 *ngIf="hasDate(message)" class="">{{message.date.toDate() | date}}</h6>
                            <h6 *ngIf="!hasDate(message)" class="">{{'cd-just-now-text' | translate}}</h6>
                        </div>

                    </div>
                </div>
            </span>

            <div *ngIf="noBoardFound | async">
                <div class="spacer-xxl"></div>
                <div class="spacer-xxl"></div>
                <div class="spacer-xxl"></div>
                <h4>{{'cd-board-removed' | translate}}</h4>
                <div class="spacer-xxl"></div>
            </div>
        </div>
    </div>

    <div *ngIf="board" class="hstack padval dimval" style=" --padding: 8px; --h: 100px; --w: 100%;">
        <mat-form-field appearance="outline" class="dimval" style="--h: 100px; --w: 80%;">
            <textarea maxlength="800" matInput placeholder="Enter your text here" rows="2"
                [(ngModel)]='newMessage'></textarea>
        </mat-form-field>
        <button [disabled]="newMessage == null || newMessage.length <= 0" mat-flat-button color="primary"
            (click)="sendMessage(newMessage)" class="">
            <span>{{ 'send' | translate }}</span>
        </button>
    </div>
</div>