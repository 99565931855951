<div class="bar hstack dimval padval header" style="--h: 60px; --w: 100%;--padding: 0 8px 0 8px;">
    <div *ngIf="showBack" onclick="history.back()">
        <span class="nav-icon material-symbols-outlined">
            navigate_before
        </span>
        <div class="spacer"></div>
    </div>
    <span *ngIf="!showBack" class="imgval logo-img" style="--h: 40px; --w: 40px;">
        <a [routerLink]="['/']">
            <img src="../../assets/logo.png">
        </a>
    </span>
    <a [routerLink]="['/']">
        <h5 class="logo-text">{{ 'app-name' | translate}}</h5>
    </a>
    <div class="spacer"></div>

    <a *ngIf="showSearch" [routerLink]="['/search']">
        <span class="action-icon material-symbols-outlined">
            search
        </span>
    </a>
    <a *ngIf="globals.user" [routerLink]="['/add']">
        <span class="action-icon material-symbols-outlined">
            add
        </span>
    </a>
    <div class="zstack isButton">
        <app-login-state-field></app-login-state-field>
        <div *ngIf=" getTotalBadgeCount()> 0" class="badge">
            <p>{{ getTotalBadgeCount() }}</p>
        </div>
    </div>
</div>