import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { AngularMaterialModule } from '../../../../../../../src/app/angular-material.module';
import { PipesModule } from '../../../../../../../src/app/pipes/pipes.module';
import { SnackbarService } from '../../../../../../../src/app/services/snackbar.service';
import { SpinnerService } from '../../../../../../../src/app/services/spinner.service';
import { UserData } from '../../../models/user';
import { FormHelperService } from '../../../../../../../src/app/utils/form-helper.service';
import { Subscription } from 'rxjs';
import { AccountManager } from '../../../services/account-manager.service';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';

@Component({
  selector: 'app-finish-registration',
  standalone: true,
  templateUrl: './finish-registration.component.html',
  styleUrl: './finish-registration.component.scss',
  imports: [
    AngularMaterialModule,
    CommonModule,
    PipesModule,
    RouterModule,
    FormsModule
  ]
})
export class FinishRegistrationComponent implements OnInit, OnDestroy {

  public user: UserData | undefined;
  public registerForm: FormGroup | undefined;

  actionName = "cloud_upload"

  public supporterId: string | null = null
  private subscription: Subscription | null = null;

  constructor(
    public snackbarService: SnackbarService,
    private accountManager: AccountManager,
    private formHelperService: FormHelperService,
    private spinnerService: SpinnerService,
    private router: Router,
    public analytics: AngularFireAnalytics,
    private activatedRoute: ActivatedRoute
  ) {
    analytics.setCurrentScreen('FinishRegistration')
    analytics.logEvent("Open-FinishRegistration")
  }
  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  ngOnInit(): void {

    this.spinnerService.show()

    this.activatedRoute.queryParams.subscribe(params => {
      this.supporterId = params['sid'] ?? null;
      if (this.supporterId) {
        window.localStorage.setItem('sid', this.supporterId);
      } else {
        this.supporterId = window.localStorage.getItem('sid');
      }
    })

    this.subscription = this.accountManager.account.subscribe(async user => {
      if (user != null) {
        this.user = user;
        this.formInit();
        this.spinnerService.hide()
        this.checkNotYetfilled()
      } else {
        this.spinnerService.hide()
        this.subscription?.unsubscribe();
        console.log("No user data!")
        this.snackbarService.openDefaultErrorSnackBar()
        this.router.navigate(['login']);
      }
    });
  }

  checkNotYetfilled() {
    if ((this.user?.firstName?.length ?? 0) > 0) {
      this.router.navigate(['']);
    }
  }

  onActionButton() {
    this.saveAccount()
  }

  public async saveAccount() {
    try {
      if (!this.user) {
        console.error("no user found")
        return
      }

      if (!this.registerForm) {
        console.log('form invalid');
        this.snackbarService.openFillAllDataBar()
        return
      }

      this.formHelperService.markFormGroupTouched(this.registerForm);

      if (this.registerForm.invalid) {
        console.log(this.registerForm.getError);
        this.snackbarService.openFillAllDataBar()
        return;
      }

      this.spinnerService.show()

      this.user.firstName = this.registerForm.value.firstName;
      // this.user.secondName = this.registerForm.value.secondName;
      // this.user.about = this.registerForm.value.about;

      if (this.supporterId) {
        console.log('sid: ' + this.supporterId)
        this.user.supporterId = this.supporterId;
      } else {
        this.user.supporterId = "OOB6km8s2pg6E6cnTYTu"
      }

      this.user.imageUrl = "";

      this.accountManager.saveAccount(this.user)
        .then(async () => {
          this.spinnerService.hide();
          this.accountManager.refreshUserAccount(this.user?.key!)

          // clean up browser
          window.localStorage.removeItem('sid');

          this.router.navigate(['']);
        })
        .catch((error) => {
          console.error(error);
          this.spinnerService.hide();
          console.log("save account 1")
          this.snackbarService.openDefaultErrorSnackBar()
        })
    } catch (error) {
      this.spinnerService.hide();
      console.log(error)
      console.log("save account 2")
      this.snackbarService.openDefaultErrorSnackBar()
    }
  }

  private formInit() {

    if (!this.user) {
      console.error("no user found")
      return
    }

    this.registerForm = new FormGroup({

      firstName: new FormControl(this.user.firstName || '',
        Validators.required),
    });
  }
}