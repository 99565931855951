import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '../../../projects/boardswap/src/app/localization/translate.service';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {

  constructor(private _snackBar: MatSnackBar,
    private translateService: TranslateService
  ) { }

  openActionPerformAfterSnackBar(message: string, action: string, onClose: (() => void)) {
    const snackBarRef = this._snackBar.open(message, action);

    snackBarRef.afterDismissed().subscribe(() => {
      onClose()
    });
  }

  openActionSnackBar(message: string, action: string) {
    this._snackBar.open(message, action);
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, 'Ok', {
      duration: 2000
    });
  }

  openDefaultErrorSnackBar() {
    // this.openSnackBar(this.translateService.translate('error_general'));
  }

  openSuccessBar() {
    this.openSnackBar(this.translateService.translate('success'));
  }

  openFillAllDataBar() {
    this.openSnackBar(this.translateService.translate('fill_all_data'));
  }

  openFillBusinessDataBar() {
    this.openSnackBar(this.translateService.translate('fill_business_data'));
  }
  openFillListingDataBar() {
    this.openSnackBar(this.translateService.translate('fill_listing_data'));
  }
  openInvalidImageBar() {
    this.openSnackBar(this.translateService.translate('invalid_image_data'));
  }

  openTryAgainBar() {
    this.openSnackBar(this.translateService.translate('try_again'));
  }

  openMailNotValidBar() {
    this.openSnackBar(this.translateService.translate('mail_not_valid'));
  }

  openMailOrPWNotValidBar() {
    this.openSnackBar(this.translateService.translate('mail_pw_not_valid'));
  }

  openPublishListingFirstBar() {
    this.openSnackBar(this.translateService.translate('publish_listing_first'));
  }

  openResendLoginMail() {
    this.openActionSnackBar(this.translateService.translate('resent_mail'), 'ok');
  }
}
