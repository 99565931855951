import { Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { AngularMaterialModule } from '../../../../../../../src/app/angular-material.module';
import { PipesModule } from '../../../../../../../src/app/pipes/pipes.module';
import { SnackbarService } from '../../../../../../../src/app/services/snackbar.service';
import { SpinnerService } from '../../../../../../../src/app/services/spinner.service';
import { ValueChecker } from '../../../../../../../src/app/utils/valuechecker.service';
import { LoginState } from '../../../enums/login-state';
import { Globals } from '../../../global';
import { AuthService } from '../../../services/auth.service';
import { HeaderComponent } from "../../shared/header/header.component";
import { CommonModule } from '@angular/common';
import { Subscription } from 'rxjs';
import { AccountManager } from '../../../services/account-manager.service';
import { TranslateService } from '../../../localization/translate.service';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';

interface QueryParams {
  [key: string]: string;
}

@Component({
  selector: 'app-passwordless-auth',
  standalone: true,
  templateUrl: './passwordless-auth.component.html',
  styleUrl: './passwordless-auth.component.scss',
  imports: [
    AngularMaterialModule,
    CommonModule,
    FormsModule,
    PipesModule,
    RouterModule,
    HeaderComponent
  ]
})
export class PasswordlessAuthComponent implements OnDestroy {

  public email: string | null = ""
  public password: string = ""
  public hidePassword = true;

  public emailSent = false;

  public loginKey = "loginKey"
  public loginKeyEmail = "loginKeyEmail"
  public registerKey = "registerKey"
  public registerKeyEmail = "registerKeyEmail"
  public loginOrRegister: string | null = this.registerKey;

  public errorMessage: string | undefined = undefined

  private subscription: Subscription | null = null;

  continueUrl: string | null = null
  supporterId: string | null = null

  constructor(
    public globals: Globals,
    public afAuth: AngularFireAuth,
    private accountManager: AccountManager,
    private authService: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private spinnerService: SpinnerService,
    public snackbarService: SnackbarService,
    private valueChecker: ValueChecker,
    private translate: TranslateService,
    public analytics: AngularFireAnalytics
  ) {
    analytics.setCurrentScreen('Auth')
    analytics.logEvent("Open-Auth")
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  ngOnInit(): void {

    if (typeof window !== 'undefined') {
      this.email = window.localStorage.getItem('emailForSignIn');
    }

    const url = this.router.url;

    this.activatedRoute.queryParams.subscribe(params => {
      this.continueUrl = params['continueUrl'] ?? null;
      if (this.continueUrl) {
        this.continueUrl = decodeURI(this.continueUrl)
      }
      this.supporterId = params['sid'] ?? null;
      if (this.supporterId) {
        window.localStorage.setItem('sid', this.supporterId);
      } else {
        this.supporterId = window.localStorage.getItem('sid');
      }
    })

    this.spinnerService.show()

    this.subscription?.unsubscribe()
    this.subscription = this.accountManager.loggedIn
      .subscribe((status) => {

        if (status === LoginState.pending) {
          return;
        }

        this.spinnerService.hide()

        if (status === LoginState.loggedOut) {
          if (url.includes('mode=signIn')) {
            this.authService.logout()
            this.confirmSignIn(url);
          }

          return
        }

        this.subscription?.unsubscribe()

        console.log('Continue: ' + this.continueUrl)
        console.log('LoginState: ' + status)
        if (this.continueUrl != undefined && this.continueUrl != null && this.canParseUrl(this.continueUrl)) {
          let pathname = new URL(this.continueUrl).pathname;
          let param = new URL(this.continueUrl).searchParams;

          let queryParams: QueryParams = {}

          param.forEach((paramValue, key) => {
            queryParams[key] = paramValue
          })
          this.continueUrl = null
          this.router.navigate([pathname], { queryParams: queryParams });
          return
        }
        this.continueUrl = null
        this.router.navigate(['']);
      });
  }

  private canParseUrl(urlString: string) {
    try {
      new URL(urlString);
      return true; // The URL is valid and can be parsed
    } catch (_) {
      return false; // The URL is invalid
    }
  }

  public pwValid(): Boolean {
    return (this.password.length >= 8)
  }

  public emailValid(): Boolean {
    return (this.email != null && this.valueChecker.isEmailValid(this.email))
  }

  async register() {

    if (!this.emailValid()) {
      this.snackbarService.openMailNotValidBar();
      return
    }

    if (this.email) {

      this.spinnerService.show()

      try {
        window.localStorage.setItem('emailForSignIn', this.email);
        let urlObj = new URL(this.continueUrl ?? this.globals.baseUrl() + '/user');
        if (this.supporterId) {
          urlObj.searchParams.set("sid", this.supporterId);
        }
        let continueAfterRegister = urlObj.toString();
        let result = await this.authService.register(this.email, continueAfterRegister)
        this.spinnerService.hide()
        if (result.success) {
          this.emailSent = true;
        } else {
          this.authService.logout()
          console.log("register first")
          this.snackbarService.openDefaultErrorSnackBar();
        }
      } catch (err) {
        this.authService.logout()
        console.log(err)
        this.spinnerService.hide()
        console.log("register second")
        this.snackbarService.openDefaultErrorSnackBar();
      }
    }
  }

  chooseLogin() {
    this.loginOrRegister = this.loginKey
  }

  chooseRegister() {
    this.loginOrRegister = this.registerKey
  }

  resetLoginRegister() {
    if (this.loginOrRegister == this.loginKeyEmail) {
      this.loginOrRegister = this.loginKey
    } else {
      this.loginOrRegister = this.registerKey
    }

    this.emailSent = false
  }

  correctMail() {
    this.resetLoginRegister()
  }

  chooseRegisterWithMail() {
    this.loginOrRegister = this.registerKeyEmail
  }

  chooseLoginWithMail() {
    this.loginOrRegister = this.loginKeyEmail
  }

  async loginWithApple() {
    this.spinnerService.show()
    let result = await this.authService.loginWithApple()
    if (result.user?.email != null && result.user?.uid != null) {
      console.log("Result Apple: " + result.user?.email)
      await this.authService.onNewSSORegister(result.user?.uid, result.user?.email)
      this.accountManager.refreshUser(true)
    }
    this.spinnerService.hide()
  }

  async loginWithGoogle() {
    this.spinnerService.show()
    let result = await this.authService.loginWithGoogle()
    if (result.user?.email != null && result.user?.uid != null) {
      console.log("Result Google: " + result.user?.email)
      await this.authService.onNewSSORegister(result.user?.uid, result.user?.email)
      this.accountManager.refreshUser(true)
    }
    this.spinnerService.hide()
  }

  async loginWithPassword() {
    if (!this.emailValid()) {
      this.snackbarService.openMailNotValidBar();
      return
    }

    if (this.email && this.password.length >= 8) {

      this.spinnerService.show()

      try {
        let result = await this.authService.loginWithPassword(this.email, this.password)
        this.spinnerService.hide()
        if (result.success) {
          this.accountManager.refreshUser(true)
          this.snackbarService.openSuccessBar()
        } else {
          this.authService.logout()
          this.snackbarService.openSnackBar(this.translate.translate('pwauth-login-error'));
        }
      } catch (err) {
        this.authService.logout()
        const error = err as any;
        console.log(err)
        this.spinnerService.hide()
        console.log("logint with pw")
        this.snackbarService.openDefaultErrorSnackBar();
      }
    } else {
      this.snackbarService.openMailOrPWNotValidBar();
    }
  }

  async sendLoginEmail() {

    if (!this.emailValid()) {
      this.snackbarService.openMailNotValidBar();
      return
    }

    if (this.email) {

      this.spinnerService.show()

      try {
        console.log(this.email)
        window.localStorage.setItem('emailForSignIn', this.email);
        let result = await this.authService.login(this.email, this.continueUrl ?? this.globals.baseUrl() + '/user')
        this.spinnerService.hide()
        if (result.success) {
          this.emailSent = true;
        } else {
          this.authService.logout()
          this.snackbarService.openSnackBar(this.translate.translate('pwauth-login-error'));
        }
      } catch (err) {
        this.authService.logout()
        const error = err as any;
        console.log(err)
        this.spinnerService.hide()
        console.log("send login mail")
        this.snackbarService.openDefaultErrorSnackBar();
      }
    }
  }

  async confirmSignIn(url: string) {

    try {
      console.log("confirmSignIn")
      this.spinnerService.show()
      if (await this.afAuth.isSignInWithEmailLink(url)) {
        this.email = window.localStorage.getItem('emailForSignIn');
        console.log(this.email)

        // If missing email, prompt user for it
        if (this.email == null) {
          let confirmEmailText = this.translate.translate('pwauth-email-confirmation')
          this.email = window.prompt(confirmEmailText)?.toLocaleLowerCase() ?? "";
          if (this.email == null) {
            this.spinnerService.hide()
            return
          }
          window.localStorage.setItem('emailForSignIn', this.email);
        }

        // Signin user and remove the email localStorage
        const result = await this.afAuth.signInWithEmailLink(this.email, url);
        if (result.user) {
          this.accountManager.refreshUser(true)
          window.localStorage.removeItem('emailForSignIn');
          console.log("clear mail 4")
        } else {
          this.snackbarService.openTryAgainBar();
        }
        this.spinnerService.hide()
      }
    } catch (err) {
      window.localStorage.removeItem('emailForSignIn');
      console.log("clear mail 5")
      this.authService.logout()
      this.loginOrRegister = null
      this.spinnerService.hide()
      this.snackbarService.openResendLoginMail();
    }
  }
}
function wait(arg0: number) {
  throw new Error('Function not implemented.');
}

