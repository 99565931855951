import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { AngularMaterialModule } from '../../../../../../../src/app/angular-material.module';
import { PipesModule } from "../../../../../../../src/app/pipes/pipes.module";
import { SnackbarService } from '../../../../../../../src/app/services/snackbar.service';
import { LanguageButtonComponent } from "../../../../../../../src/app/views/general/language-button/language-button.component";
import { Globals } from '../../../global';
import { BoardData } from '../../../models/board';
import { FooterComponent } from "../../shared/footer/footer.component";
import { HeaderComponent } from "../../shared/header/header.component";
import { LoginStateFieldComponent } from '../../shared/login-state-field/login-state-field.component';
import { SpinnerService } from '../../../../../../../src/app/services/spinner.service';
import { ImageGalleryComponent } from '../../shared/image-gallery/image-gallery.component';
import { TranslateService } from '../../../localization/translate.service';
import { BoarddataService } from '../../../services/boarddata.service';
import { Availability } from '../../../models/availability';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';

@Component({
  selector: 'app-board-detail',
  standalone: true,
  templateUrl: './board-detail.component.html',
  styleUrl: './board-detail.component.scss',
  imports: [
    PipesModule,
    CommonModule,
    RouterModule,
    AngularMaterialModule,
    LoginStateFieldComponent,
    LanguageButtonComponent,
    HeaderComponent,
    FooterComponent
  ]
})
export class BoardDetailComponent implements OnInit {

  static boardKey: string | null = null
  board: BoardData | null = null

  constructor(
    private overlay: Overlay,
    private snackbarService: SnackbarService,
    private boardService: BoarddataService,
    public globals: Globals,
    private router: Router,
    private spinnerService: SpinnerService,
    private translateService: TranslateService,
    private route: ActivatedRoute,
    public analytics: AngularFireAnalytics
  ) {
    analytics.setCurrentScreen('BoardDetail')
    analytics.logEvent("Open-BoardDetail")

    this.route.queryParams.subscribe(params => {
      BoardDetailComponent.boardKey = params['bkey'];
    })
  }

  Availability = Availability
  Date = Date
  actionName = this.translateService.translate('bd-request')

  ngOnInit(): void {
    this.initData()
  }

  onActionButton() {

    if (this.disableActionButton()) {
      return
    }

    if (this.board?.ownerKey != null && this.board?.ownerKey == this.globals.user?.key) {
      this.router.navigate(['/edit'], {
        queryParams: { 'bkey': this.board.key }
      });
    } else {
      this.router.navigate(['/contact'], {
        queryParams: { 'bkey': this.board!.key }
      });
    }
  }

  disableActionButton(): Boolean {
    if (this.board?.ownerKey != this.globals.user?.key) {
      if (this.board?.availability == Availability.sold || this.board?.availability == Availability.deleted) {
        return true
      }
    } else if (this.board?.availability == Availability.deleted) {
      return true
    }

    return false
  }

  async initData() {

    if (!BoardDetailComponent.boardKey) {
      console.log("BoardDetailComponent")
      this.snackbarService.openDefaultErrorSnackBar()
      return
    }

    this.spinnerService.show()

    this.board = await this.boardService.getBoard(BoardDetailComponent.boardKey)

    if (this.board?.ownerKey != null && this.board?.ownerKey == this.globals.user?.key) {
      this.actionName = this.translateService.translate('bd-edit')
    } else {
      this.actionName = this.translateService.translate('bd-request')
    }

    this.spinnerService.hide()
  }

  photoUrl(index: number): string | null {
    if (this.board!.photos.length > index) {
      return this.board!.photos[index]['data'] ?? null
    }
    return null
  }

  openOverlay(index: number) {

    if (this.board?.photos == null) {
      return
    }

    ImageGalleryComponent.currentIndex = index;
    ImageGalleryComponent.images = this.board!.photos.map((p) => p['data'])

    const overlayRef = this.overlay.create({
      hasBackdrop: true,
      positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
      scrollStrategy: this.overlay.scrollStrategies.block(),
    });

    const filePreviewPortal = new ComponentPortal(ImageGalleryComponent);

    // Attach ComponentPortal to PortalHost
    const ref = overlayRef.attach(filePreviewPortal);
    ref.instance.closePanel.subscribe(() => overlayRef.detach());

    overlayRef.backdropClick().subscribe(() => {
      overlayRef.detach();
    });
  }
}
