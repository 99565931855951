import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AngularMaterialModule } from '../../../angular-material.module';
import { PipesModule } from "../../../pipes/pipes.module";
import { LanguageButtonComponent } from "../language-button/language-button.component";
import { LoginStateFieldComponent } from '../../../../../projects/boardswap/src/app/views/shared/login-state-field/login-state-field.component';

@Component({
  selector: 'app-toolbar',
  standalone: true,
  templateUrl: './toolbar.component.html',
  styleUrl: './toolbar.component.scss',
  imports: [
    AngularMaterialModule,
    CommonModule,
    RouterModule,
    PipesModule,
    LoginStateFieldComponent,
    LanguageButtonComponent
  ]
})
export class ToolbarComponent {




}
